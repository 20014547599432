<template>
  <bg-modal
    v-model="isShowModal"
    title="Aktifkan Naik Kelas"
    description="Dengan mengisi data di bawah ini, program Naik Kelas
    diaktifkan dan properti memiliki kontrak kerja sama yang baru."
    desktop-size="lg"
    :body-scroll="false"
    button-main-text="Aktifkan Naik Kelas"
    :button-main-props="{
      disabled: !isFormValid,
      loading: isLoadingSubmission
    }"
    class="property-class-upgrade-form-modal"
    @click-main-action="submitForm"
  >
    <div class="bg-u-mt-xl property-class-upgrade-form-modal__row">
      <bg-field label="Basic Commission Saat Ini">
        <bg-input
          :value="currentBasicCommissionValue"
          disabled
          suffix="%"
          data-testid="propertyClassUpgradeFormModal-currentBasicCommission"
        />
      </bg-field>

      <bg-field
        label="Basic Commission Naik Kelas"
        label-for="classUpgradeBasicCommission"
        :error="!!classUpgradeCommissionError"
        :message="classUpgradeCommissionError"
      >
        <bg-input
          id="classUpgradeBasicCommission"
          v-model.trim="classUpgradeBasicCommission"
          suffix="%"
          data-testid="propertyClassUpgradeFormModal-upgradedBasicCommission"
          @input="setClassUpgradeCommission"
        />
      </bg-field>
    </div>

    <div class="property-class-upgrade-form-modal__row">
      <bg-field label="Tenor">
        <bg-input
          v-model.trim="tenor"
          suffix="Bulan"
          data-testid="propertyClassUpgradeFormModal-tenor"
          @input="setTenor"
        />
      </bg-field>

      <search-checkbox
        label="Kamar Naik Kelas"
        name="classUpgradeRoomlist"
        :placeholder="
          isLoadingRoomList
            ? 'Memuat Daftar Kamar...'
            : 'Pilih Kamar Naik Kelas'
          "
        :list="roomList"
        :checked="selectedRoomList"
        :is-loading-list="isLoadingRoomList"
        :max-checked="0"
        :max-counter-display="0"
        has-select-all-option
        select-all-option-label="Semua Kamar"
        disable-search
        disable-clear
        data-testid="propertyClassUpgradeFormModal-roomList"
        @emit-checked-array="setSelectedRoomList"
      />
    </div>

    <div class="property-class-upgrade-form-modal__row">
      <bg-field label="Tanggal Awal Repayment">
        <bg-datepicker
          v-model="repaymentStartDate"
          format="dd MMMM yyyy"
          placeholder="Isi tanggal awal repayment"
          data-testid="propertyClassUpgradeFormModal-repaymentStartDate"
        />
      </bg-field>

      <bg-field label="Tanggal Akhir Repayment">
        <bg-input
          :value="repaymentEndDate.displayValue"
          disabled
          placeholder="Isi tanggal akhir repayment"
          data-testid="propertyClassUpgradeFormModal-repaymentEndDate"
          icon-right="calendar"
        />
      </bg-field>
    </div>
  </bg-modal>
</template>

<script>
import { ref, computed, watch, getCurrentInstance } from '@vue/composition-api';
import {
  BgModal,
  BgField,
  BgInput,
  BgDatepicker,
} from 'bangul-vue';
import dayjs from 'dayjs';

import SearchCheckbox from '@admin_molecules/SearchCheckbox';

import propertyClassUpgradeAPI from '@admin/api/endpoints/property-class-upgrade';

import { useModalVisibility } from '@/composables/useModalVisibility';

import { cleanNumber, onlyNumber } from '@/utils/formatter';

export default {
  name: 'PropertyClassUpgradeFormModal',

  components: {
    BgModal,
    BgField,
    BgInput,
    BgDatepicker,
    SearchCheckbox,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    currentBasicCommission: {
      type: String,
      required: true,
      default: '',
    },

    propertyId: {
      type: [String, Number],
      required: true,
      default: '',
    }
  },

  setup(props, { emit }) {
    const instance = getCurrentInstance();
    const { $toast, $error } = instance.proxy;

    const { isShowModal } = useModalVisibility(props, emit);

    const currentBasicCommissionValue = computed(() =>
      onlyNumber(props.currentBasicCommission)
    );

    const classUpgradeBasicCommission = ref('');

    const setClassUpgradeCommission = value => {
      classUpgradeBasicCommission.value = onlyNumber(value);
    };

    const classUpgradeCommissionError = computed(() => {
      if (cleanNumber(classUpgradeBasicCommission.value) > 100) {
        return 'Tidak boleh melebihi 100%';
      }

      return '';
    });

    const tenor = ref('');

    const setTenor = value => {
      tenor.value = onlyNumber(value);
    };

    const repaymentStartDate = ref(null);
    const repaymentEndDate = computed(() => {
      if (!tenor.value || !repaymentStartDate.value) {
        return { displayValue: '', realValue: '' };
      }

      const endDate = dayjs(repaymentStartDate.value).add(
        tenor.value - 1, 'month'
      );

      return {
        displayValue: endDate.format('DD MMMM YYYY'),
        realValue: endDate.format('YYYY-MM-DD'),
      }
    });

    const isLoadingRoomList = ref(false);
    const roomList = ref([]);
    const selectedRoomList = ref([]);

    const fetchRoomList = async () => {
      try {
        isLoadingRoomList.value = true;

        const response = await propertyClassUpgradeAPI
          .getPropertyClassUpgradeRoomList(
            props.propertyId
          );

        if (response && response.data) {
          const { rooms } = response.data;

          roomList.value = rooms.map(({ id, number, type }) => ({
            value: id,
            label: `${type} - ${number}`
          }));
        }
      } catch (error) {
        const errorMessage =
          error?.response?.data?.error || 'Terjadi galat, silakan coba lagi.';

        $toast.show(errorMessage);
        $error.report(error);
      } finally {
        isLoadingRoomList.value = false;
      }
    };

    const setSelectedRoomList = selectedRooms => {
      selectedRoomList.value = selectedRooms;
    };

    const isFormValid = computed(() =>
      !!classUpgradeBasicCommission.value &&
      !classUpgradeCommissionError.value &&
      !!tenor.value &&
      !!repaymentStartDate.value &&
      !!selectedRoomList.value.length
    );

    const resetForm = () => {
      classUpgradeBasicCommission.value = '';
      tenor.value = '';
      repaymentStartDate.value = null;
      selectedRoomList.value = [];
    };

    watch(isShowModal, value => {
      if (value) {
        fetchRoomList();
      } else {
        resetForm();
      }
    }, { immediate: true });

    const isLoadingSubmission = ref(false);

    const submitForm = async () => {
      if (!isFormValid.value) {
        return;
      }

      try {
        isLoadingSubmission.value = true;

        const data = {
          mamikos_gain_percentage:
            cleanNumber(classUpgradeBasicCommission.value) / 100,
          first_repayment_date:
            dayjs(repaymentStartDate.value).format('YYYY-MM-DD'),
          final_repayment_date: repaymentEndDate.value.realValue,
          marked_rooms: selectedRoomList.value
        };

        const response = await propertyClassUpgradeAPI
          .postPropertyClassUpgradeActivation(
            props.propertyId,
            data
          );

        if (response.status === 200) {
          isShowModal.value = false;
          emit('submitted');
        }
      } catch (error) {
        const errorMessage =
          error?.response?.data?.error || 'Terjadi galat, silakan coba lagi.';

        $toast.show(errorMessage);
        $error.report(error);
      } finally {
        isLoadingSubmission.value = false;
      }
    };

    return {
      isShowModal,

      currentBasicCommissionValue,
      classUpgradeBasicCommission,
      classUpgradeCommissionError,
      setClassUpgradeCommission,

      tenor,
      setTenor,

      repaymentStartDate,
      repaymentEndDate,

      isLoadingRoomList,
      roomList,
      selectedRoomList,
      fetchRoomList,
      setSelectedRoomList,

      isFormValid,
      isLoadingSubmission,
      submitForm,
    };
  },
}
</script>

<style lang="scss" scoped src="./PropertyClassUpgradeFormModal.scss" />

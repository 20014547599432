<template>
  <bg-modal
    class="deactivation-modal"
    v-model="isShowModal"
    desktop-size="lg"
    title="Akhiri Naik Kelas"
    description="Dengan mengisi data di bawah ini, program Naik Kelas
    dinonaktifkan dan properti memiliki kontrak kerja sama yang baru. Pastikan
    Basic Commission Baru sudah sesuai."
    button-main-text="Akhiri Naik Kelas"
    :button-main-props="{
      disabled: !isValidForm,
      loading: isLoadingSubmission
    }"
    @click-main-action="submitForm"
  >
    <div class="deactivation-modal__fields">
      <bg-field
        label="Basic Commission Naik Kelas"
        label-for="currentBasicCommission"
      >
        <bg-input
          id="currentBasicCommission"
          :value="currentBasicCommissionValue"
          suffix="%"
          disabled
        />
      </bg-field>

      <bg-field
        label="Basic Commission Baru"
        label-for="newBasicCommission"
        :error="!!newBasicCommissionError"
        :message="newBasicCommissionError"
      >
        <bg-input
          id="newBasicCommission"
          v-model.trim="newBasicCommission"
          placeholder="Isi basic commission baru"
          suffix="%"
          @input="setNewBasicCommission"
        />
      </bg-field>
    </div>
  </bg-modal>
</template>

<script>
import { computed, ref, watch, getCurrentInstance } from '@vue/composition-api';
import { BgModal, BgField, BgInput } from 'bangul-vue';

import propertyClassUpgradeAPI from '@admin/api/endpoints/property-class-upgrade';

import { useModalVisibility } from '@/composables/useModalVisibility';
import { cleanNumber, onlyNumber } from '@/utils/formatter';

export default {
  name: 'PropertyClassUpgradeDeactivationModal',

  components: {
    BgModal,
    BgField,
    BgInput,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    currentBasicCommission: {
      type: [String, Number],
      required: true,
      default: ''
    },
    basicCommissionBeforeClassUpgrade: {
      type: [String, Number],
      required: true,
      default: ''
    },
    propertyId: {
      type: [String, Number],
      required: true,
      default: '',
    }
  },

  setup(props, { emit }) {
    const instance = getCurrentInstance();
    const { $toast, $error } = instance.proxy;

    const currentBasicCommissionValue = computed(() =>
      onlyNumber(props.currentBasicCommission)
    );

    const { isShowModal } = useModalVisibility(props, emit);

    const newBasicCommission = ref('');

    const setNewBasicCommission = value => {
      newBasicCommission.value = onlyNumber(value);
    };

    const newBasicCommissionError = computed(() => {
      if (cleanNumber(newBasicCommission.value) > 100) {
        return 'Tidak boleh melebihi 100%';
      }

      return '';
    });

    watch(
      isShowModal,
      isShow => {
        if (isShow) {
          setNewBasicCommission(props.basicCommissionBeforeClassUpgrade);
        }
      },
      { immediate: true }
    );

    const isValidForm = computed(
      () => !!newBasicCommission.value && !newBasicCommissionError.value
    );

    const isLoadingSubmission = ref(false);

    const submitForm = async () => {
      if (!isValidForm.value) {
        return;
      }

      try {
        isLoadingSubmission.value = true;

        const data = {
          mamikos_gain_percentage: cleanNumber(newBasicCommission.value) / 100
        };

        const response = await propertyClassUpgradeAPI
          .postPropertyClassUpgradeDeactivation(
            props.propertyId,
            data
          );

        if (response.status === 200) {
          isShowModal.value = false;
          emit('submitted', { isActivation: false });
        }
      } catch (error) {
        const errorMessage =
          error?.response?.data?.error || 'Terjadi galat, silakan coba lagi.';

        $toast.show(errorMessage);
        $error.report(error);
      } finally {
        isLoadingSubmission.value = false;
      }
    };

    return {
      currentBasicCommissionValue,
      isShowModal,
      newBasicCommission,
      newBasicCommissionError,
      setNewBasicCommission,
      isValidForm,
      isLoadingSubmission,
      submitForm
    };
  },
};
</script>

<style
  lang="scss"
  src="./PropertyClassUpgradeDeactivationModal.scss"
  scoped
></style>
